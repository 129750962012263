import { isTesting, macroCondition } from '@embroider/macros';
import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';
import type IntlService from 'ember-intl/services/intl';

export const INVOICE_STATUSES = {
  toReview: 'to_review',
  toApprove: 'to_approve',
  awaitingPayment: 'awaiting_payment',
  /**
   * @deprecated use toReview instead
   * After Approval Workflow on Supplier Invoices feature is released, the Supplier Invoices with toPay status will be migrated to toReview status.
   */
  toPay: 'to_pay',
  pending: 'pending',
  scheduled: 'scheduled',
  paid: 'paid',
  inbox: 'inbox',
  archived: 'archived',
  rejected: 'rejected',
};

export const INVOICE_OR_CREDIT_NOTE_TEXT = {
  creditNote: 'credit note',
  invoice: 'invoice',
};

export const ISSUE_DATE_FILTER_OPTIONS = {
  THIS_MONTH: 'this_month',
  PAST_MONTH: 'past_month',
};

export const DEFAULT_FILE_TYPES_ACCEPTED = '.pdf,.jpg,.jpeg,.png';
export const FILE_TYPES_ACCEPTED_WITH_XML = '.pdf,.jpg,.jpeg,.png,.xml';

export const EDITABLE_DESCRIPTION_INVOICE_STATUSES = [
  INVOICE_STATUSES.toReview,
  INVOICE_STATUSES.toPay,
  INVOICE_STATUSES.paid,
];

export const INVOICE_SOURCES = {
  emailForward: 'email_forward',
  payByInvoice: 'pay_by_invoice',
  eInvoicing: 'e_invoicing',
  supplierInvoices: 'supplier_invoices',
  integration: 'integration',
};

export const DUE_FILTER_EVENT_MAP = {
  past_and_today: 'due',
  future: 'upcoming',
  missing_date: 'missing',
};

export const STATUS_COLORS = {
  inbox: 'gray',
  toPay: 'purple',
  toReview: 'gray',
  pending: 'orange',
  scheduled: 'purple',
  paid: 'green',
  archived: 'gray',
};

export const TABLE_STATUS_TAG_LEVELS = {
  inbox: 'in-progress',
  toPay: 'in-progress',
  toApprove: 'in-progress',
  toReview: 'in-progress',
  pending: 'warning',
  scheduled: 'waiting',
  paid: 'validated',
  archived: 'inactive',
  rejected: 'error',
  awaitingPayment: 'in-progress',
} as const;

export const STATUS_TAG_LEVELS = {
  inbox: 'inactive',
  toPay: 'inactive',
  toReview: 'inactive',
  toApprove: 'in-progress',
  awaitingPayment: 'in-progress',
  rejected: 'error',
  pending: 'warning',
  scheduled: 'waiting',
  paid: 'validated',
  archived: 'inactive',
  einvoice: 'waiting',
} as const;

export const BASE_FLOW_STATUSES = [
  INVOICE_STATUSES.inbox,
  INVOICE_STATUSES.scheduled,
  INVOICE_STATUSES.paid,
];

export const ALL_STATUS = [
  INVOICE_STATUSES.inbox,
  INVOICE_STATUSES.pending,
  INVOICE_STATUSES.scheduled,
  INVOICE_STATUSES.paid,
];

export const INSIGHT_LABELS = {
  inboxAmount: 'inbox',
  scheduledAmount: 'scheduled',
  paidAmount: 'paid_last_30_days',
  pendingAmount: 'pending',
  inboxCounter: 'inbox_status_counter',
  awaitingPaymentCounter: 'awaiting_payment_status_counter',
  toApproveCounter: 'to_approve_status_counter',
  pendingCounter: 'pending_status_counter',
  scheduledCounter: 'scheduled_status_counter',
  paidCounter: 'paid_status_counter',
  missingAmountsCounter: 'missing_amounts_counter',
  fxInvoicesCounter: 'fx_invoices_counter',
  totalDueAmount: 'total_due_amount',
  totalDueMissingAmmountCounter: 'total_due_missing_amount_counter',
  totalDueCounter: 'total_due_counter',
  totalDueFxCounter: 'total_due_fx_counter',
  totalOverdueCounter: 'total_overdue_counter',
  totalUpcomingAmount: 'total_upcoming_amount_next_90_days',
  totalUpcomingCounter: 'total_upcoming_counter_next_90_days',
  totalUpcomingFxCounter: 'total_upcoming_fx_counter_next_90_days',
  totalUpcomingMissingAmountCounter: 'total_upcoming_missing_amount_counter_next_90_days',
  toReviewDuplicatesCounter: 'to_review_duplicates_counter',
  toReviewUnrecognizedCounter: 'to_review_non_financial_documents_counter',
  toReviewMissingDataCounter: 'to_review_missing_data_counter',
};

export const COCKPIT_INSIGHTS = 'cockpit-insights';

export const GENERIC_IBAN = 'FR14 2004 1010 0505 0001 3M02 606';

export const WAIT_FOR_OCR_SCAN = macroCondition(isTesting()) ? 500 : 6000;
export const ANIMATION_IN_DURATION_MS = macroCondition(isTesting()) ? 500 : 4000;
export const TRANSACTION_UPDATE_DELAY_MS = macroCondition(isTesting()) ? 0 : 3000;

export const SOURCE_TYPES = {
  EMAIL: 'email_forward',
  PAY_BY_INVOICE: 'pay_by_invoice',
  E_INVOICING: 'e_invoicing',
  SUPPLIER_INVOICES: 'supplier_invoices',
  INTEGRATION: 'integration',
};

export const LOKALIZE_SOURCE_TYPES = (intl: IntlService, source: string): string | undefined => {
  const sourceTypes = {
    [SOURCE_TYPES.EMAIL]: intl.t('supplier-invoices.filters.imported-via.email-forward'),
    [SOURCE_TYPES.PAY_BY_INVOICE]: intl.t('supplier-invoices.filters.imported-via.direct-upload'),
    [SOURCE_TYPES.E_INVOICING]: intl.t('supplier-invoices.filters.imported-via.e-invoicing'),
    [SOURCE_TYPES.SUPPLIER_INVOICES]: intl.t(
      'supplier-invoices.filters.imported-via.direct-upload'
    ),
    [SOURCE_TYPES.INTEGRATION]: intl.t('supplier-invoices.filters.imported-via.integration'),
  };

  return sourceTypes[source];
};

export const BASE_INSIGHTS = [INSIGHT_LABELS.totalDueAmount, INSIGHT_LABELS.totalUpcomingAmount];

export const INSIGHTS_PREFERENCE_STORAGE_KEY = 'supplier_invoices_insights_preference';

export const DUE_DATE_FILTER = {
  FUTURE: 'future',
  PAST_AND_TODAY: 'past_and_today',
  MISSING_DATE: 'missing_date',
};

export const EXPORT_FORMATS = {
  PDF: 'pdf',
  FATTURAPA: 'fatturapa',
  XLS: 'xls',
};

export const EXPORT_ERROR_TYPES = {
  LIMIT_REACHED: 'limit_reached',
  NO_INVOICES: 'no_invoices_to_export',
  GENERIC_ERROR: 'generic_error',
};

export const FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY =
  'fr-first-supplier-einvoice-instructional-tooltip-dismissed';

export const FRENCH_EINVOICE_PAYMENT_STORAGE_KEY =
  'french-einvoice-payment-product-discovery-card-dismissed';

export const FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY =
  'first-german-e-invoice-instructional-tooltip-dismissed';

export const EINVOICING_LIFECYCLE_EVENT_DESCRIPTION = (
  intl: IntlService,
  statusCode: number
): string | undefined => {
  const descriptions: Record<number, string> = Object.freeze({
    203: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-203'),
    204: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-204'),
    205: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-205'),
    206: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-206'),
    207: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-207'),
    208: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-208'),
    209: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-209'),
    210: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-210'),
    211: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-211'),
    212: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-212'),
    213: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-213'),
    214: intl.t('supplier-invoices.einvoicing-timeline.event-description.event-214'),
  });

  return descriptions[statusCode];
};

export const EINVOICING_LIFECYCLE_EVENT_STATUS_CODES = [
  203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 214,
];

export const EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES = [206, 207, 208, 214];

export const EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES = [210];

export const TABS = {
  TASKS: 'tasks',
  ALL_INVOICES: 'all_invoices',
  INBOX: 'inbox',
  TO_APPROVE: 'to_approve',
  TO_PAY: 'to_pay',
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
} as const;

export const FILTERS = {
  ASSIGNED_TO_YOU: 'assigned_to_you',
  SUPPLIERS: 'suppliers',
  TO_REVIEW: 'to_review',
  DUE_DATE: 'due_date',
  MULTI_STATUS: 'multi_status',
  PAYMENT_DATE: 'payment_date',
  ARCHIVED_PAID_STATUS: 'archived_paid_status',
  NO_MATCHED_TRANSACTIONS: 'no_matched_transactions',
  DUPLICATES: 'duplicates',
  NON_FINANCIAL_DOCUMENT: 'non_financial_documents',
  MISSING_DATA: 'missing_data',
};

export const PAYMENT_DATE_FILTER_OPTIONS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'this_week',
  PAST_WEEK: 'past_week',
  THIS_MONTH: 'this_month',
  PAST_MONTH: 'past_month',
};

/**
 * TODO change back to constant when feature--boolean-approval-workflow-for-supplier-invoices is released
 * @param {boolean} featureBooleanApprovalWorkflowForSupplierInvoices - variation('feature--boolean-approval-workflow-for-supplier-invoices')
 */
export const STATUS_TABS_MAP = (featureBooleanApprovalWorkflowForSupplierInvoices: boolean) => {
  return {
    [TABS.TASKS]: INVOICE_STATUSES.pending,
    [TABS.ALL_INVOICES]: [
      INVOICE_STATUSES.toReview,
      ...(featureBooleanApprovalWorkflowForSupplierInvoices
        ? [INVOICE_STATUSES.toApprove, INVOICE_STATUSES.awaitingPayment, INVOICE_STATUSES.rejected]
        : []),
      INVOICE_STATUSES.archived,
      INVOICE_STATUSES.paid,
      INVOICE_STATUSES.scheduled,
    ],
    [TABS.INBOX]: INVOICE_STATUSES.toReview,
    [TABS.TO_APPROVE]: INVOICE_STATUSES.toApprove,
    [TABS.TO_PAY]: featureBooleanApprovalWorkflowForSupplierInvoices
      ? INVOICE_STATUSES.awaitingPayment
      : INVOICE_STATUSES.toReview,
    [TABS.SCHEDULED]: INVOICE_STATUSES.scheduled,
    [TABS.COMPLETED]: featureBooleanApprovalWorkflowForSupplierInvoices
      ? [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived, INVOICE_STATUSES.rejected]
      : [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived],
  };
};

/**
 * TODO change back to constant when feature--boolean-approval-workflow-for-supplier-invoices is released
 * @param {boolean} featureBooleanApprovalWorkflowForSupplierInvoices - variation('feature--boolean-approval-workflow-for-supplier-invoices')
 */
export const SORT_TABS_MAP = (
  featureBooleanApprovalWorkflowForSupplierInvoices: boolean = false
) => {
  return {
    [TABS.TASKS]: 'due_date:asc',
    [TABS.ALL_INVOICES]: 'created_at:desc',
    [TABS.INBOX]: 'due_date:asc',
    [TABS.TO_APPROVE]: 'due_date:asc',
    [TABS.TO_PAY]: featureBooleanApprovalWorkflowForSupplierInvoices
      ? 'due_date:asc'
      : 'due_date:desc',
    [TABS.SCHEDULED]: 'payment_date:asc',
    [TABS.COMPLETED]: 'payment_date:desc',
  };
};

export const GERMAN_INVOICE_FORMATS = {
  ZUGFERD: 'zugferd',
  XRECHNUNG: 'xrechnung',
  OTHER: 'other',
};

export const GERMAN_E_INVOICES_ARTICLE_FOR_DE = 'https://qonto.com/de/invoicing/e-invoicing';
export const GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS = 'https://qonto.com/en-de/invoicing/e-invoicing';

export const NAVATIC_TOUR_URLS = {
  [LOCALES_DEFINITION.EN.code]: 'https://qonto.navattic.com/bqa0kux',
  [LOCALES_DEFINITION.FR.code]: 'https://qonto.navattic.com/sc10n5j',
  [LOCALES_DEFINITION.IT.code]: 'https://qonto.navattic.com/3n102gd',
  [LOCALES_DEFINITION.ES.code]: 'https://qonto.navattic.com/pa1052j',
  [LOCALES_DEFINITION.DE.code]: 'https://qonto.navattic.com/hhuu09v1',
  [LOCALES_DEFINITION.PT.code]: 'https://qonto.navattic.com/ve260kz9',
};

export const PREVIEW_TYPES = {
  SUGGESTED: 'suggested',
  RELATED: 'related',
};

export const SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY =
  'suggest-transactions-popup-dismissed';
