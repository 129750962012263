export const GBR_DE = 'GbR';

export const DEPOSIT_CAPITAL_FLOW_TYPE = {
  STANDARD: 'standard',
  FAST_TRACK: 'fast-track',
};

export const DEPOSIT_CAPITAL_STEPPER_STEP = {
  SHAREHOLDERS: 0,
  DOCUMENTS: 1,
  CAPITAL_DEPOSIT: 2,
  DEPOSIT_CERTIFICATE: 3,
  COMPANY_REGISTRATION: 4,
  FUND_RELEASE: 5,
  FINISH: 6,
};

export const DEPOSIT_CAPITAL_STATUS = {
  PENDING_REVIEW: 'pending_review',
  WAITING: 'waiting',
  DEPOSIT_REQUEST_SENT: 'deposit_request_sent',
  DEPOSIT_REQUEST_SIGNED: 'deposit_request_signed',
  DEPOSIT_CERTIFICATE_SENT: 'deposit_certificate_sent',
  DEPOSIT_CERTIFICATE_SIGNED: 'deposit_certificate_signed',
  KBIS_SUBMITTED: 'kbis_submitted',
  DEPOSIT_RELEASE_SENT: 'deposit_release_sent',
  DEPOSIT_RELEASE_REQUESTED: 'deposit_release_requested',
};

export const FAST_TRACK_DEPOSIT_CAPITAL_STATUS = {
  // company verification is NOT a deposit capital status
  // - it's a KYC membership status
  // - it can be anything but NOT "accepted" status (currently only "refused" or "pending")
  COMPANY_VERIFICATION: 'company_verification',

  // "standard" deposit capital statuses
  DEPOSIT_REQUEST_SENT: DEPOSIT_CAPITAL_STATUS.DEPOSIT_REQUEST_SENT,
  DEPOSIT_REQUEST_SIGNED: DEPOSIT_CAPITAL_STATUS.DEPOSIT_REQUEST_SIGNED,
  DEPOSIT_CERTIFICATE_SIGNED: DEPOSIT_CAPITAL_STATUS.DEPOSIT_CERTIFICATE_SIGNED,
  KBIS_SUBMITTED: DEPOSIT_CAPITAL_STATUS.KBIS_SUBMITTED,
  DEPOSIT_RELEASE_REQUESTED: DEPOSIT_CAPITAL_STATUS.DEPOSIT_RELEASE_REQUESTED,
};

export const ORGA_STATUS = {
  KYB_WAITING: 'kyb_waiting',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
  SUSPENDED: 'suspended',
};

export const ORGA_SUSPENDED_REASON = {
  EXPIRED_DUE_DILIGENCE: 'expired_due_diligence',
  EXPIRED_POI: 'expired_poi',
};

export const GEO_EXPANSION_LEGAL_COUNTRIES = ['BE', 'PT', 'AT', 'NL'];

export const DEFAULT_LEGAL_COUNTRIES = ['DE', 'ES', 'FR', 'IT'];

export const KYB_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  DEACTIVATED: 'deactivated',
};

export const DEACTIVATION_REASON_DETAILS = {
  EXPIRED_DUE_DILIGENCE: 'expired_due_diligence',
};
