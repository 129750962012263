import { getUrlsTable } from '@repo/shared-config/app/constants/hosts';

import ENV from 'qonto/config/environment';

let { backendUrlsTable, frontendUrlsTable } = getUrlsTable(ENV);

export const amazonS3AssetsUrl = 'https://qonto-assets.s3.amazonaws.com';

export const {
  announcementBaseURL,
  apiBaseURL,
  authBaseURL,
  billerBaseURL,
  companyCreationBaseURL,
  registerBaseURL,
  registerPartnersBaseURL: registerPartnersURL,
  hubBaseURL,
  notifierBaseURL,
  websocketBaseURL,
} = backendUrlsTable;

export const {
  companyCreationJsURL,
  receivableInvoicesURL,
  registerJsURL,
  registerPartnersJsURL,
  switchingBankJsURL,
  regateOauthURL,
} = frontendUrlsTable;

// in Qonto APIs, namespaces are applied to resources
export const apiNamespace = 'v1';
export const attachmentCollectorNamespace = 'v1';
export const authNamespace = 'v1';
export const billerNamespace = 'v1';
export const billerV2Namespace = 'v2';
export const billerV3Namespace = 'v3';
export const billerV4Namespace = 'v4';
export const companyCreationNamespace = 'v1';
export const registerNamespace = 'v1';
export const authUserNamespace = 'v2';
export const mandateNamespace = 'v2';

export const bankAccountNamespace = 'v5';
export const savingsAccountNamespace = 'v3';
export const transactionNamespace = 'v7';
export const upcomingTransactionsNamespace = 'v1';
export const transactionOldExportNamespace = 'v5';
export const transactionNewExportNamespace = 'v6';
export const organizationNamespace = 'v3';
export const attachmentNamespace = 'v3';
export const membershipNamespace = 'v3';
export const membershipV4Namespace = 'v4';
export const membershipAllowedBankAccountsNamespace = 'v1';
export const transferNamespace = 'v4';
export const cardNamespace = 'v4';
export const cardQcpNamespace = 'v5';
export const checkNamespace = 'v3';
export const cashFlowCategoriesNamespace = 'v1';
export const cashFlowCategoriesNamespaceV2 = 'v2';
export const countryNamespace = 'v2';
export const conciergeRequestNamespace = 'v3';
export const directDebitNamespace = 'v3';
export const directDebitCollectionsNamespace = 'v1';
export const directDebitCollectionActivationNamespace = 'v1';
export const incomeNamespace = 'v3';
export const labelListNamespace = 'v3';
export const labelNamespace = 'v3';
export const walletToWalletNamespace = 'v7';
export const beneficiaryNamespace = 'v4';
export const multiTransfersNamespace = 'v3';
export const hubNamespace = 'v1';
export const notifierNamespace = 'v1';
export const statementNamespace = 'v3';
export const inviteNamespace = 'v3';
export const inviteV4Namespace = 'v4';
export const stakeholderNamespace = 'v3';
export const legalEntityNamespace = 'v3';
export const f24Namespace = 'v1';
export const nrcPaymentNamespace = 'v1';
export const pagopaNamespace = 'v1';
export const counterNamespaceV3 = 'v3';
export const searchPresetNamespace = 'v3';
export const teamNamespace = 'v3';
export const requestsNamespace = 'v3';
export const requestsV4Namespace = 'v4';
export const receivableInvoiceNamespace = 'v4';
export const remindersConfigurationNamespace = 'v4';
export const remuneratedAccountNamespace = 'v1';
export const supplierInvoiceNamespace = 'v1';
export const registerPartnersNamespace = 'client_api';
export const receiptRemindersNamespace = 'v3';
export const teamBudgetNamespace = 'v1';
export const oauthNamespace = 'v1';
export const bookkeepingTransactionsNamespace = 'v1';
export const financingInstallmentsNamespace = 'v1';
export const paymentLinkV1Namespace = 'v1';
export const paymentLinkNamespace = `${paymentLinkV1Namespace}/payment_links`;
export const mollieNamespace = 'v1/mollie';
export const paymentMethodNamespace = 'v4';
export const generatedLogoNamespace = 'v1';
export const internationalOutNamespace = 'v1';
export const financingNamespace = 'financing/v1';
export const financingV2Namespace = 'financing/v2';
export const financingV3Namespace = 'financing/v3';
export const financingV4Namespace = 'financing/v4';
export const directDebitCollectionClientsNamespace = 'v1';
export const bankConnectionNamespace = 'v1';
export const directDebitCollectionMandatesNamespace = 'v1';
export const directDebitSubscriptionsNamespace = 'v1';
export const userActionsNamespace = 'v2';
export const tableTransactionsNamespace = 'v1';
export const clientHubNamespace = 'v1';
export const clientHubV2Namespace = 'v2';
export const supplierHubNamespace = `${supplierInvoiceNamespace}/supplier_invoices`;
export const productsNamespace = 'v2';
export const einvoicingSettingsNamespace = 'v1';
export const insuranceHubNamespace = 'v1';
export const subscriptionNamespace = 'v1';
export const billingNamespace = `${billerV4Namespace}/billing`;
export const solutionInstanceV2Namespace = 'integrations/v2';
export const externalBankRecommendationsNamespace = 'v1';
export const cardAcquirerNamespace = 'card_acquirer/v1';
export const chargbackClaimsNamespace = 'v1';
export const tableViewsNamespace = 'v1';
export const ribaPaymentNamespace = 'v1';
